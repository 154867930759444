<template>
  <div class="content-noticias-detalle">
    <div v-if="image" class="background-banner display-flex align-items-center justify-content-center position-relative"
      :style="{ backgroundImage: `url(${image})` }" />
    <div v-else
      class="background-banner-empty display-flex align-items-center justify-content-center position-relative" />
    <!-- <img v-else src="@/assets/images/img-placeholder.png"> -->

    <div>
      <v-container fluid class="global-paddings">
        <v-row>
          <v-col cols="12" md="6" offset-md="3">
            <div class="content-texts">
              <p class="txt-title" v-html="title" />
              <div class="display-flex align-items-center">
                <v-icon color="#000" size="16px">mdi-calendar</v-icon>
                <p class="txt-date mb-0">{{ date }}</p>
              </div>
              <div class="line" />
              <p class="txt-description" v-html="description" />
              <div class="line" />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="mb-9">
        <v-row>
          <v-col>
            <div>
              <p class="title-section">Te podría interesar</p>
              <noticias-component />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import NoticiasComponent from "@/components/Noticias.vue";

export default {
  name: "NoticiasDetalleView",
  data: () => ({
    image: "",
    title: "",
    date: "",
    description: "",
  }),
  beforeMount() {
    this.getPrensaByID();
  },
  methods: {
    getPrensaByID() {
      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        };
      window.DB.get(
        `${window.URI}/articles/${this.$route.params.id}`,
        config,
        payload
      )
        .then((response) => {
          this.image = response.data.article.sImageUrl;
          this.title = response.data.article.sTitle;
          this.date = response.data.article.tPublishDate;
          this.description = response.data.article.sContent;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.Error(error.response.data);
        });



    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refreshTable() {
      this.getPrensaByID();

    }
  },
  components: {
    NoticiasComponent,
  },
};
</script>

<style scoped>
.title-section {
  text-align: center;
  font-size: 70px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.line {
  border: 1px solid #e2e2e2;
  width: 120%;
  margin-left: -55px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.content-texts {
  padding: 50px 0px 0px 0px;
}

.txt-title {
  text-align: left;
  font-size: 40px;
  letter-spacing: 0.44px;
  color: #000000de;
  opacity: 1;
  overflow-wrap: break-word;
}

.txt-date {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.txt-description {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  text-align: justify;
  text-justify: inter-word;
  overflow-wrap: break-word;
}

.content-noticias-detalle {
  padding-top: 96px;
  transition: none !important;
}

.background-banner {
  height: 400px;
  max-height: 400px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: none !important;
}

.background-banner-empty {
  height: 400px;
  max-height: 400px;
  width: 100%;
  background-image: url("../assets/images/img-placeholder.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: none !important;
}

@media (max-width: 600px) {
  .title-section {
    font-size: 40px;
  }
}
</style>