<template>
  <div class="content-noticias">
    <div class="background-banner display-flex align-items-center justify-content-center position-relative"
      :style="{ backgroundImage: backgroundImage }">
      <p>Prensa</p>
      <!-- content input  -->
      <div class="px-content-input">
        <v-text-field v-model="sSearch" class="input-search" placeholder="Buscar noticia" filled rounded dense
          append-icon="mdi-magnify" color="#000" hide-details height="40px" background-color="#fff" />
      </div>
    </div>
    <div class="container-cards">
      <v-container fluid class="global-paddings">
        <v-row>
          <v-col v-for="(item, index) in items" :key="index" cols="12" sm=6 md="3">
            <div @click="$router.push({ name: 'PrensaDetalle', params: { id: item.sArticleId } })"
              class="px-content-card">
              <div class="content-image">
                <img v-if="item.sImageUrl" :src="item.sImageUrl">
                <img v-else src="@/assets/images/img-placeholder.png">
              </div>
              <div class="px-body-card">
                <p class="txt-title">{{ item.sTitle }}</p>
                <p class="txt-description">{{ item.sContent }}</p>
                <p class="txt-date">
                  {{ item.tPublishDate }}
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
        <div v-if="items.length === 0" class="content-empty-card" :style="{ 'height': iHeight + 'px' }">
          <span class="txt-empty-card">"Sin información"</span>
        </div>
      </v-container>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "NoticiasView",
  data() {
    return {
      iHeight: 0,
      backgroundImage: `url(${require("@/assets/images/banner.png")})`,
      items: [
        // {
        //   id: "frfer-45445-test-rt-rtrtrt-666",
        //   image: "http://via.placeholder.com/300",
        //   title: "First-line item",
        //   description:
        //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Assumenda pariatur quisquam, iste mollitia exercitationem, modi odio reprehenderit aut velit, molestias molestiae qui fugiat quo veniam accusamus laudantium commodi? Aspernatur, sed.",
        // },
      ],
      sSearch: "",
    }
  },
  beforeMount() {
    this.getPressAndNews();

  },
  beforeDestroy() {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },

  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize() {
      this.iHeight = window.innerHeight - 670
    },
    getPressAndNews() {
      this.items = []
      let params = {
        sSearch: this.sSearch,
      };
      DB.get(`${URI}/articles`, {
        params,
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.items = response.data.articles
        })
        .catch((error) => {
          // if (error.response.status == 401) {
          //   this.Error("Tu sesión ha finalizado.");
          //   const loading = this.$vs.loading({
          //     type: "circles",
          //     color: "#F1D72E",
          //   });
          //   setTimeout(() => {
          //     localStorage.clear();
          //     window.location.reload();
          //     loading.close();
          //   }, 3000);
          // }
          this.Error(error.response.data.message);
        });
    },
  },
  watch: {
    sSearch() {
      this.getPressAndNews();
    },
  }
};
</script>
  
<style>
.px-content-input .v-input--hide-details>.v-input__control>.v-input__slot {
  box-shadow: 0px 3px 6px #00000029 !important;
}
</style>
  
<style scoped>
.content-empty-card {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.txt-empty-card {
  margin-bottom: 0px !important;
  font-style: italic;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "GaraPro-Regular" !important;
  color: #b9bfa9 !important;
}

.px-content-input {
  position: absolute;
  bottom: -15px;
  width: 646px;
}

.px-body-card {
  padding: 20px;
}

.txt-title {
  text-align: left;
  font-size: 22px;
  letter-spacing: 0.18px;
  color: #000000de;
  opacity: 1;
  /* two lines  */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.txt-description {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.13px;
  color: #00000099;
  opacity: 1;
  /* two lines  */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
}

.txt-date{
  text-align: center;
  margin-top: 3px;
  font-size: 14px;
  letter-spacing: 0.13px;
  color: #a3332c;
  opacity: 1;
}

.content-noticias {
  padding-top: 96px;
}

.px-content-card {
  height: 300px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.px-content-card:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 0.5;
}

.container-cards {
  padding: 50px 0px;
}

.background-banner {
  height: 300px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-banner p {
  text-align: center;
  font-size: 70px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
}

.content-image {
  width: 100%;
  height: 150px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 110%;
    object-fit: contain;
    object-position: center center;
    border-radius: 3px;
  }
}

@media (max-width: 960px) {
  .px-content-input {
    width: 446px;
  }
}

@media (max-width: 600px) {
  .px-content-input {
    width: 300px;
  }
}
</style>