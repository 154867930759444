<template>
  <div>
    <effect-component />
  </div>
</template>

<script>
import EffectComponent from "@/components/Effect.vue";

export default {
  name: "OutsideView",
  components: {
    EffectComponent,
  },
};
</script>