<template>
  <div class="content-slide open" :class="(slide) ? 'open' : 'close'">
    <v-btn class="button-close" @click="setSlide(false)" icon>
      <v-icon color="#000" size="40px">
        mdi-close
      </v-icon>
    </v-btn>
    <div class="">
      <div class="display-flex align-items-center justify-content-center mt-9">
        <div class="content-logo">
          <img src="@/assets/images/logo.png" alt="">
        </div>
      </div>
      <div class="content-txt-slide">
        <p @click="$router.push({ name: 'Inicio' }).catch(e => { }); setSlide(false)"
          :style="(selected == 'inicio') ? { fontWeight: 'bold' } : {}" class="txt-slide">Inicio</p>
        <p @click="$router.push({ name: 'Libros' }).catch(e => { }); setSlide(false)"
          :style="(selected == 'libros') ? { fontWeight: 'bold' } : {}" class="txt-slide">Libros</p>
        <p @click="$router.push({ name: 'Autora' }).catch(e => { }); setSlide(false)"
          :style="(selected == 'autora') ? { fontWeight: 'bold' } : {}" class="txt-slide">Autora</p>
        <p @click="$router.push({ name: 'Reseña' }).catch(e => { }); setSlide(false)"
          :style="(selected == 'resena') ? { fontWeight: 'bold' } : {}" class="txt-slide">Reseñas</p>
        <p :style="(selected == 'prensa') ? { fontWeight: 'bold' } : {}" class="txt-slide"
          @click="$router.push({ name: 'Prensa' }).catch(e => { }); setSlide(false)">Prensa</p>
        <p @click="redirectContacto" class="txt-slide">Contacto</p>
      </div>
      <div class="display-flex align-items-center justify-content-center">
        <div class="social-media">
          <img @click="url('https://www.instagram.com/mai_gs_ya/')" src="@/assets/images/instagram.png">
          <img @click="url('https://www.facebook.com/mai.gs1')" src="@/assets/images/facebook.png">
          <img @click="url('https://twitter.com/Mai_Gs1')" src="@/assets/images/twitter.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SlideComponent",
  data: () => ({
    screenHeight: 0,
    selected: "inicio",
  }),
  beforeMount() {
    this.selected = this.$route.path.split("/")[1];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    redirectContacto: function () {
      this.setSlide(false);
      this.$router.push({ name: "Inicio" }).catch((e) => ({}));
      setTimeout(() => {
        let contacto = document.getElementById("contacto");
        window.scrollTo({
          top: contacto.offsetTop - 80,
          behavior: "smooth",
        });
      }, 100);
    },
    setSlide: function (val) {
      this.$store.commit("setSlide", val);
    },
    handleResize: function () {
      this.screenHeight = window.innerHeight;
      if (window.innerWidth > 960) {
        this.setSlide(false);
      }
    },
  },
  computed: {
    slide: function () {
      return this.$store.state.slide;
    },
  },
  watch: {
    $route: function () {
      this.selected = this.$route.path.split("/")[1];
    },
  },
};
</script>

<style scoped>
.social-media {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.social-media img {
  width: 24px;
  height: 24px;
  margin-left: 15px;
}

.social-media img:hover {
  cursor: pointer;
  filter: brightness(0.5);
}

.txt-slide {
  text-align: center;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.txt-slide:hover {
  cursor: pointer;
  /* text-decoration: underline; */
}

.txt-slide::first-letter {
  font-size: 38px;
}

.content-txt-slide {
  margin-top: 40px;
}

.content-logo {
  width: 80%;
}

.content-logo img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-logo img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

.button-close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.content-slide {
  background-color: #fff;
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: auto;
}

.open {
  height: 100%;
  transition: height 0.5s;
}

.close {
  height: 0%;
  transition: height 0.5s;
}

@media (max-width: 600px) {
  .txt-slide {
    font-size: 25px;
  }

  .txt-slide::first-letter {
    font-size: 33px;
  }
}
</style>