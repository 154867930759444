var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    width: ("" + _vm.fullWidth),
    height: ("" + _vm.fullHeight),
    overflow: 'hidden',
    position: 'fixed',
  })},[_c('div',{staticClass:"content-effect"},[_c('div',{staticClass:"shadow"},[_c('div',{staticClass:"triangle",style:([
          {
            width: ("" + _vm.fullWidth),
            height: ("" + _vm.middleHeight),
          },
          _vm.cssProps ]),attrs:{"id":"triangle"}})]),_c('div',{staticClass:"square",style:({
        width: ("" + _vm.fullWidth),
        height: ("" + _vm.fullHeight),
      }),attrs:{"id":"square"}})]),_c('v-btn',{staticClass:"button-content-gif",attrs:{"ripple":false}},[_c('div',{staticClass:"content-click-effect"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showGif),expression:"showGif"}],staticClass:"click-effect",on:{"click":_vm.showEffect}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.imgLogoShow),expression:"imgLogoShow"}],staticClass:"img-logo-full",attrs:{"src":require('@/assets/images/pedazos/MaiGsselloFull.png')}}),_c('img',{staticClass:"img-logo",attrs:{"id":"imgLogo","src":require('@/assets/images/pedazos/MaiGssello.png')}}),_c('img',{staticClass:"img-logo1",attrs:{"id":"imgLogo1","src":require('@/assets/images/pedazos/MaiGssello1.png')}}),_c('img',{staticClass:"img-logo2",attrs:{"id":"imgLogo2","src":require('@/assets/images/pedazos/MaiGssello2.png')}}),_c('img',{staticClass:"img-logo3",attrs:{"id":"imgLogo3","src":require('@/assets/images/pedazos/MaiGssello3.png')}}),_c('img',{staticClass:"img-logo4",attrs:{"id":"imgLogo4","src":require('@/assets/images/pedazos/MaiGssello4.png')}}),_c('img',{staticClass:"img-logo5",attrs:{"id":"imgLogo5","src":require('@/assets/images/pedazos/MaiGssello5.png')}})])]),_c('div',{staticStyle:{"z-index":"1","position":"relative"}},[_c('navbar-component'),_c('inicio-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }