<template>
  <div>
    <navbar-component />
    <router-view />
    <slide-component />
    <footer-component />
  </div>
</template>

<script>
import NavbarComponent from "@/components/Navbar.vue";
import SlideComponent from "@/components/Slide.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  name: "InsideView",
  components: {
    NavbarComponent,
    SlideComponent,
    FooterComponent,
  },
};
</script>