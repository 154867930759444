import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    slide: false,
    refresh: false,
  },
  mutations: {
    setSlide(state, value) {
      state.slide = value;
    },
    refresher(state, item) {
      state.refresh = item;
    },
  },
  actions: {},
  modules: {},
});
