export const notification = {
  methods: {

    Success(
      textTitle = "",
      textDescription = "",
      position = "top-right",
      duration = null,
      color = "success",
    ) {
      this.$vs.notification({
        color,
        duration,
        position,
        title: textTitle,
        text: textDescription,
      });
    },
    Error(
      textTitle = "",
      textDescription = "",
      position = "top-right",
      duration = null,
      color = "danger",
    ) {
      this.$vs.notification({
        duration,
        color,
        position,
        title: textTitle,
        text: textDescription,
      });
    },
    Warning(
      textTitle = "",
      textDescription = "",
      position = "top-right",
      color = "warn",
      duration = null
    ) {
      this.$vs.notification({
        duration,
        color,
        position,
        title: textTitle,
        text: textDescription,
      });
    },
    Info(
      textTitle = "",
      textDescription = "",
      position = "top-right",
      color = "primary",
      duration = null
    ) {
      this.$vs.notification({
        duration,
        color,
        position,
        title: textTitle,
        text: textDescription,
      });
    },
  },
};
