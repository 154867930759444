import Vue from "vue";
import VueRouter from "vue-router";
import Inside from "@/views/Inside.vue";
import Outside from "@/views/Outside";
import Inicio from "@/views/Inicio.vue";
import Autora from "@/views/Autora.vue";
import Libros from "@/views/Libros.vue";
import LibrosDetalle from "@/views/LibrosDetalle.vue";
import Reseña from "@/views/Reseña.vue";
import PrensaDetalle from "@/views/PrensaDetalle.vue";
import Prensa from "@/views/Prensa.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Outside",
    component: Outside,
  },
  {
    path: "/inicio",
    name: "Inside",
    component: Inside,
    children: [
      {
        path: "/inicio",
        name: "Inicio",
        component: Inicio,
      },
      {
        path: "/autora",
        name: "Autora",
        component: Autora,
      },
      {
        path: "/libros",
        name: "Libros",
        component: Libros,
      },
      {
        path: "/libros/:name",
        name: "LibrosDetalle",
        component: LibrosDetalle,
      },
      {
        path: "/prensa",
        name: "Prensa",
        component: Prensa,
      },
      {
        path: "/prensa/:id",
        name: "PrensaDetalle",
        component: PrensaDetalle,
      },
      {
        path: "/resena",
        name: "Reseña",
        component: Reseña,
      },
    ],
  },
  {
    path: "*",
    // route level code-splitting
    redirect: "/",
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  // When opening a new route send the user to the default location (top left)
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
