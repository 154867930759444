<template>
  <div class="content-autora">
    <div
      class="background-banner display-flex align-items-center justify-content-center position-relative"
      :style="{ backgroundImage: backgroundImage }"
    >
      <p>Autora</p>
    </div>
    <v-container
      fluid
      class="global-paddings content-txts-autora"
    >
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="5"
        >
          <div class="content-image">
            <img src="@/assets/images/autora.png">
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="7"
        >
          <div class="add-padding">
            <div class="display-flex align-items txts-acerca">
              <p>ACERCA</p>
              <p>DE</p>
              <p>MI…</p>
            </div>
            <p class="txt-nombre snell">María Fernanda</p>
            <p class="txt-description">María Fernanda García Sada (Monterrey, N.L., 1973) es licenciada en Estudios Internacionales, escritora y artista visual. Egresó de la Universidad de Monterrey en 1997. Hizo estudios de Gemología en Brasil con el maestro Rui Ribeiro, así como Protocolo y Etiqueta en el Institut Villa Pierrefeu en Suiza; Orfebrería, Diseño y Arte en el Instituto per l’Arte e il Restauro en Italia y Derecho de Arte y Administración de Galerías en Sotheby’s Institute of Art en los Estados Unidos. Expuso en la Galería Emma Molina (2000 – 2006) y en GE Galería (2008 – 2012); Maestra de taller artístico privado y fundadora de la Galería Art Suite en la Ciudad de México. Cuenta con más de veinte exposiciones tanto de manera individual como colectiva en galerías y museos en México y en el extranjero. En la escritura fue colaboradora de la revista <span style="font-style: italic;">The Business Club Magazine</span> con la columna <span style="font-style: italic;">Arte, cultura y sociedad</span>, así como también en la edición <span style="font-style: italic;">Woman</span> con la columna <span style="font-style: italic;">Women to Watch</span> de 2008 a 2012. Realizó diplomados en Creación Literaria en el Museo MARCO y en la Fábrica Literaria. En el 2013 escribió <span style="font-style: italic;">El punto animado</span> (edición de autor). Ha estudiado Taller de Novela con Mariana García Luna, Haiku con Cristina Rascón y Poesía con Amaranta Caballero Prado. También estudió en Gotham Writers en Nueva York, en los Estados Unidos (2017-2019).</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "AutoraView",
  data: () => ({
    backgroundImage: `url(${require("@/assets/images/banner.png")})`,
  }),
};
</script>

<style scoped>
.content-txts-autora {
  padding: 50px 0px;
}

.txts-acerca {
  text-align: left;
  font-size: 18px;
  letter-spacing: 8px;
  color: #000000;
  opacity: 1;
}

.txts-acerca p::first-letter {
  font-size: 23px;
}

.txts-acerca p:nth-child(2) {
  margin-left: 5px;
  margin-right: 5px;
}

.txt-nombre {
  text-align: left;
  font-size: 80px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-left: 25px;
}

.txt-description {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #212121;
  opacity: 1;
  text-align: justify;
  text-justify: inter-word;
  line-height: 2;
}

.content-autora {
  padding-top: 96px;
}

.background-banner {
  height: 300px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-banner p {
  text-align: center;
  font-size: 70px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
}

.content-image {
  width: 100%;
  height: 500px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: top center;
  }
}

@media (max-width: 960px) {
  .content-image img {
    object-fit: cover;
    min-width: 100%;
  }
  .global-paddings {
    padding: 0px !important;
  }
  .add-padding {
    padding: 20px;
  }
  .txts-acerca {
    justify-content: center;
  }
  .txt-nombre {
    text-align: center;
    font-size: 60px;
  }
}
</style>