<template>
  <div class="content-noticias">
    <div class="background-banner display-flex align-items-center justify-content-center position-relative"
      :style="{ backgroundImage: backgroundImage }">
      <p>Reseñas</p>
    </div>
    <div class="container-cards">
      <v-container fluid class="global-paddings">
        <v-row>
          <!-- v-for="(item, i) in items" :key="i" -->
          <v-col v-for="(item, i) in items" :key="i" v-if="items.length > 0" cols="12" sm=6 md="4" class="card-resena pb-7 pt-7">
            <img class="img-book" :src="getImgBook(item.iBook)">

            <div class="box" :id="'box' + i" :class="className">
              <p :style="{ maxHeight: item.bShowMore ? item.expandedTextHeight + 'px' : '' }" class="text"
                ref="contentText">
                {{item.sMessage}}
              </p>

              <!-- <v-btn icon class="btn-arrow" @click="handleShowMore(item,i)"><i class="arrow"></i></v-btn> -->
              <div class="text-show-more" @click="handleShowMore(item,i)" style="display:none">
                <span>{{ item.bShowMore ? 'Ver menos' : 'Ver más' }}</span>
              </div>
            </div>
            <p class="txt-name">{{ item.sName }}</p>
            <p class="txt-job">{{ item.sJobDescription }}</p>


            <!-- <div class="content-card-all">
              <img class="img-book" :src="getImgBook(item.iBook)">
              <div class="content-texts" :id="'ContentText' + i">
                <p class="txt-message" :id="'TextMessage' + i">{{ item.sMessage }}</p>
                <div class="text-show-more" @click="setExpand(i, item)" style="display:none">
                  <span>{{ item.bShowMore ? 'Ver menos' : 'Ver más' }}</span>
                </div>
              </div>
              <p class="txt-name">{{ item.sName }}</p>
            </div> -->
          </v-col>
        </v-row>
        <div v-if="items.length === 0" class="content-empty-card" :style="{ 'height': iHeight + 'px' }">
          <span class="txt-empty-card">"Sin información"</span>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>

export default {
  name: "NoticiasView",
  expose: ['setShowMore'],
  data: () => ({
    iHeight: 0,
    backgroundImage: `url(${require("@/assets/images/banner.png")})`,
    puesto: "",
    items: [
    ],
    itemsCol1: [],
    itemsCol2: [],
    itemsCol3: [],
    sSearch: "",


    showMore: false,
    expandedTextHeight: 0,
    className: "",
  }),
  updated() {
    this.countLines();

  },
  beforeMount() {
    this.getReseñas();
  },
  mounted() {

    this.countLines();
    this.expandedTextHeight = this.$refs.contentText.scrollHeight;

  },
  created() {
    window.addEventListener("resize", this.countLines);
    this.countLines();
  },
  destroyed() {
    window.removeEventListener("resize", this.countLines);
  },
  methods: {
    getReseñas() {
      let params = {
        // sSearch: this.sSearch,
      };
      DB.get(`${URI}/messages`, {
        params,
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.items = response.data.messages.map((e) => {
            console.log(e)
            return {
              ...e,
              bShowMore: false, 
              expandedTextHeight: 800,

            }
          })

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.Error(error.response.data.message);
        });
    },
    getImgBook(iBook) {
      switch (iBook) {
        case 1:
          // return require("@/assets/images/libro1.png");
          return require("@/assets/images/libros/Libro3.png");

        case 2:
          return require("@/assets/images/libros/Libro2.png");
        case 3:
          return require("@/assets/images/libros/Libro1.png");

        default:
          return require("@/assets/images/img-placeholder.png");
      }
    },
    countLines(element) {
      this.iHeight = window.innerHeight - 670

      if (document.getElementsByClassName("text").length > 0) {
        let aElement = document.getElementsByClassName("text")
        let aHeightCards = []
        for (let i = 0; i < aElement.length; i++) {
          const element = aElement[i];
          let ElementMore = document.getElementsByClassName("text-show-more")[i]


          if (element.scrollHeight > 195) {
            ElementMore.style.display = "block"
          } else {
            ElementMore.style.display = "none"
          }
          aHeightCards.push(element.scrollHeight)
        }
      }
    },
    setExpand(i, item) {

      // var elementContentText = document.getElementById("ContentText" + i);
      // elementContentText.classList.toggle("open")

      var elementContentText = document.getElementById("ContentText" + i);
      elementContentText.classList.toggle("content-texts-expand")

      var elementTextMessage = document.getElementById("TextMessage" + i);
      elementTextMessage.classList.toggle("txt-message-expand")
      item.bShowMore = !item.bShowMore
    },
    handleShowMore(item, i) {
      item.bShowMore = !item.bShowMore;
      let box = document.getElementById('box' + i)
      if (item.bShowMore) {
        box.classList.add('open')
        // this.className = "open";
      } else {
        // After .5s remove class 'open'
        setTimeout(() => (
          box.classList.remove('open')

        ), 500);
      }
    },
  }
};
</script>

<style>
.px-content-input .v-input--hide-details>.v-input__control>.v-input__slot {
  box-shadow: 0px 3px 6px #00000029 !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card-resena{
  position: relative;
}

/* Box */
.box {
  margin: 22px auto;
  width: 100%;
  min-height: 320px;
  padding: 12px 8px 65px;
    background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  overflow: hidden;
}

.text {
  max-height: 195px;
  text-align: justify;
  /* Init 3 line height */
  padding: 30px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0px 0;
  position: relative;
  line-clamp: 8;
  -webkit-line-clamp: 8;
  transition: all 0.8s cubic-bezier(0.9, 0, 0.8, 0.2);
}

.open .text {
  line-clamp: initial;
  -webkit-line-clamp: initial;
  transition: all 0.8s cubic-bezier(0.9, 0, 0.8, 0.2);
}

/* Irrelavant css... */
.arrow {
  border: solid #000;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.open .arrow {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-top: 5px;
}

.btn-arrow {
  background: transparent;
  border: 2px solid #000;
  height: 35px;
  width: 32px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
</style>

<style scoped>
/* #region card all style new */
.content-card-all {
  transition: all 1s ease-in-out !important;

  position: relative;
  width: 100%;
  height: 100% !important;
  /* background-color: red; */
  margin-bottom: 50px;

}

.img-book {
  position: absolute;
  width: 80px;
  height: 80px;
  top: -15px;
  left: 40%;
}

.content-texts {
  transition: all 1s ease-in-out !important;
  height: 250px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
  padding: 20px !important;
}

.content-texts-expand {
  transition: all 1s ease-in-out !important;

  height: auto !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
  padding: 20px !important;
}

.txt-message {
  transition: all 1s ease-in-out !important;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.13px;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #000000 !important;
  /* two lines  */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 10em;
  animation: 1s delay-overflow;
}

.txt-message-expand {
  animation: 1s delay-overflow;
  transition: all 1s ease-in-out !important;

  text-align: left;
  font-size: 18px;
  letter-spacing: 0.13px;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #000000 !important;
  /* two lines  */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 100;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 200em;

}

.text-show-more {
  /* position: absolute; */
  text-align: center;
  text-decoration: underline;
  /* bottom: 65px; */
  width: 100%;
  left: 0%;
  right: 0%;
  cursor: pointer;
}

.txt-name {
  /* position: absolute; */
  /* bottom: 40px; */
  left: 0%;
  right: 0%;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.18px;
  color: #00000099;
  opacity: 1;
  /* two lines  */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: -85px 0px 50px 0px;
}
.txt-job {
  /* position: absolute; */
  /* bottom: 40px; */
  left: 0%;
  right: 0%;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.18px;
  color: #00000099;
  opacity: 1;
  /* two lines  */
  display: -webkit-box;
  max-width: 97%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: -45px 0px 40px 0px;
  padding-left: 3%;
}



/* #endregion card all style new */

/* #region content card */
.content-noticias {
  padding-top: 96px;
}

.content-card-close {
  position: relative;
  height: 350px;
}

.content-card {
  position: relative;
  /* height: 350px; */
  height: auto;
}

.px-content-card-close {
  margin-top: 40px;
  height: 250px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.px-content-card {
  margin-top: 40px;
  height: 250px;
  /* height: auto; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.px-content-card {
  margin-top: 40px;
  /* height: 250px; */
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.px-content-input {
  position: absolute;
  bottom: -15px;
  width: 646px;
}

.px-body-card {
  position: relative;
  padding: 20px;
}



.container-cards {
  padding: 50px 0px;
}

/* #endregion content card */

/* #region text css */
.txt-title-close {
  /* position: absolute; */
  bottom: 60px;
  left: 0%;
  right: 0%;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.18px;
  color: #00000099;
  opacity: 1;
  margin-bottom: 0px;
  /* two lines  */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* margin-bottom: 0; */
}



.txt-title {
  position: absolute;
  bottom: 50px;
  left: 0%;
  right: 0%;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.18px;
  color: #00000099;
  opacity: 1;
  margin-bottom: 0px;
  /* two lines  */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* margin-bottom: 0; */
}

.txt-description-close {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.13px;
  color: #000000 !important;
  /* two lines  */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.txt-description {
  text-align: left;

  font-size: 18px;
  letter-spacing: 0.13px;
  color: #000000 !important;
  /* two lines  */
  /* display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden; */
}

/* #endregion text css */


/* #region banner */
.background-banner {
  height: 300px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-banner p {
  text-align: center;
  font-size: 70px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
}

/* #endregion banner */

/* #region img reseña */
.content-image {
  position: absolute;
  top: 0%;
  width: 80px;
  height: 80px;
  left: 40%;
}

.content-image img {
  width: 100%;
  height: auto;
  border-radius: 100% !important;
  border: #000000 1px solid;

}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 3px;
  }
}

/* #endregion img reseña */

/* #region css responsive */

@media (max-width: 960px) {
  .px-content-input {
    width: 446px;
  }
}

@media (max-width: 600px) {
  .px-content-input {
    width: 300px;
  }
}

/* #endregion css mobresponsiveile */
</style>