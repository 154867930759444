import { render, staticRenderFns } from "./Effect.vue?vue&type=template&id=32265bf2&scoped=true&"
import script from "./Effect.vue?vue&type=script&lang=js&"
export * from "./Effect.vue?vue&type=script&lang=js&"
import style0 from "./Effect.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Effect.vue?vue&type=style&index=1&id=32265bf2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32265bf2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
