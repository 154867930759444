<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  }),
};
</script>

<style>
* {
  transition: all .6s ease-in-out !important;
}

html,
body {
  margin: 0;
  font-family: "garamond", sans-serif !important;
}

.v-application {
  font-family: "garamond", sans-serif !important;
}

/* import fonts */
@font-face {
  font-family: "garamond";
  src: url("../src/assets/fonts/AGaramondPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: "snell";
  src: url("../src/assets/fonts/SnellBT-Regular.otf") format("opentype");
}

.font-style-italica {
  font-style: italic !important;
}
.txt-letter-space-0{
  letter-spacing: 0px !important;
}

.txt-letter-space-1{
  letter-spacing: 1px !important;
}

.garamond {
  font-family: "garamond", sans-serif;
}

.snell {
  font-family: "snell", sans-serif;
}

/*#region scroll-login-form */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #737373;

  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #737373;
}

/* global classes */
.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.position-relative {
  position: relative;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.global-paddings {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

@media (min-width: 961px) and (max-width: 1260px) {
  .global-paddings {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media (max-width: 960px) {
  .global-paddings {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
</style>