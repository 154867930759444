import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import { notification } from "./notification";
import VueGlide from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css"; //Vuesax styles
Vue.use(Vuesax, {
  // options here
});
Vue.mixin(notification);

Vue.use(VueGlide);
Vue.config.productionTip = false;

window.DB = axios;

window.URI = "https://api.maigs.mx/api/v1";

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
