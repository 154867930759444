<template>
  <div v-if="desktop">
    <div
      v-if="(scrolled && selected == 'inicio') || $route.name == 'Outside'"
      class="global-paddings position-relative"
    >
      <div class="display-flex align-items-center justify-content-center">
        <div class="content-image">
          <img src="@/assets/images/logo.png" alt="" />
        </div>
      </div>
      <div
        class="content-texts display-flex align-items-center justify-content-center full-width"
      >
        <p
          @click="$router.push({ name: 'Inicio' }).catch((e) => {})"
          :class="selcontact ? 'contact-select' : 'contact-select-marker'"
          class="txt-navbar mb-0"
        >
          Inicio
        </p>
        <p
          @click="$router.push({ name: 'Libros' }).catch((e) => {})"
          :style="selected == 'libros' ? { fontWeight: 'bold' } : {}"
          class="txt-navbar mb-0"
        >
          Libros
        </p>
        <p
          @click="$router.push({ name: 'Autora' }).catch((e) => {})"
          :style="selected == 'autora' ? { fontWeight: 'bold' } : {}"
          class="txt-navbar mb-0"
        >
          Autora
        </p>
        <p class="txt-navbar-logo mb-0"></p>
        <p
          @click="$router.push({ name: 'Reseña' }).catch((e) => {})"
          :style="selected == 'resena' ? { fontWeight: 'bold' } : {}"
          class="txt-navbar mb-0"
        >
          Reseñas
        </p>
        <p
          @click="$router.push({ name: 'Prensa' }).catch((e) => {})"
          :style="selected == 'prensa' ? { fontWeight: 'bold' } : {}"
          class="txt-navbar mb-0"
        >
          Prensa
        </p>
        <p
          @click="redirectContacto"
          class="txt-navbar mb-0"
          style="margin-left: 4vw"
          :style="selcontact ? { fontWeight: 'bold' } : {}"
        >
          Contacto
        </p>
      </div>
      <div class="social-media">
        <img
          @click="url('https://www.instagram.com/mai_gs_ya/')"
          src="@/assets/images/instagram.png"
        />
        <img
          @click="url('https://www.facebook.com/mai.gs1')"
          src="@/assets/images/facebook.png"
        />
        <img
          @click="url('https://twitter.com/art_suite_g')"
          src="@/assets/images/twitter.png"
        />
      </div>
    </div>
    <div class="content-navbar-scrolled fixed-top" v-else>
      <div class="content-image-scrolled">
        <img src="@/assets/images/logo-responsive.png" alt="" />
      </div>
      <v-spacer />
      <div class="display-flex align-items-center justify-content-center full-width">
        <p
          @click="$router.push({ name: 'Inicio' }).catch((e) => {})"
          :class="selcontact ? 'contact-select' : ''"
          class="txt-navbar-scrolled ml-5 mb-0"
        >
          Inicio
        </p>
        <p
          @click="$router.push({ name: 'Libros' }).catch((e) => {})"
          :style="selected == 'libros' ? { fontWeight: 'bold' } : {}"
          class="txt-navbar-scrolled ml-5 mb-0"
        >
          Libros
        </p>
        <p
          @click="$router.push({ name: 'Autora' }).catch((e) => {})"
          :style="selected == 'autora' ? { fontWeight: 'bold' } : {}"
          class="txt-navbar-scrolled ml-5 mb-0"
        >
          Autora
        </p>
        <p
          @click="$router.push({ name: 'Reseña' }).catch((e) => {})"
          :style="selected == 'resena' ? { fontWeight: 'bold' } : {}"
          class="txt-navbar-scrolled ml-5 mb-0"
        >
          Reseñas
        </p>
        <p
          @click="$router.push({ name: 'Prensa' }).catch((e) => {})"
          style="margin-left: 8px"
          :style="selected == 'prensa' ? { fontWeight: 'bold' } : {}"
          class="txt-navbar-scrolled ml-5 mb-0"
        >
          Prensa
        </p>
        <p
          @click="redirectContacto"
          class="txt-navbar-scrolled ml-5 mb-0"
          :style="selcontact ? { fontWeight: 'bold' } : {}"
        >
          Contacto
        </p>
      </div>
      <v-spacer />
      <div class="social-media-scrolled">
        <img
          @click="url('https://www.instagram.com/mai_gs_ya/')"
          src="@/assets/images/instagram.png"
        />
        <img
          @click="url('https://www.facebook.com/mai.gs1')"
          src="@/assets/images/facebook.png"
        />
        <img
          @click="url('https://twitter.com/art_suite_g')"
          src="@/assets/images/twitter.png"
        />
      </div>
    </div>
  </div>
  <div class="content-navbar-responsive fixed-top-responsive" v-else>
    <v-btn @click="setSlide(true)" icon>
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <v-spacer />
    <div class="content-image-responsive">
      <img src="@/assets/images/logo-sello.png" />
    </div>
    <v-spacer />
    <div class="social-media-scrolled">
      <img
        @click="url('https://www.instagram.com/mai_gs_ya/')"
        src="@/assets/images/instagram.png"
      />
      <img
        @click="url('https://www.facebook.com/mai.gs1')"
        src="@/assets/images/facebook.png"
      />
      <img
        @click="url('https://twitter.com/art_suite_g')"
        src="@/assets/images/twitter.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarComponent",
  data: () => ({
    scrolled: true,
    desktop: true,
    selected: "inicio",
    selcontact: false,
  }),
  beforeMount() {
    this.selected = this.$route.path.split("/")[1];
  },
  created() {
    window.addEventListener("scroll", this.scrollChangeNavbar);
    this.scrollChangeNavbar();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollChangeNavbar);
    window.removeEventListener("scroll", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 3120) {
        this.selcontact = true;
      } else {
        this.selcontact = false;
      }
    },
    redirectContacto: function () {
      this.selcontact = true;
      this.$router.push({ name: "Inicio" }).catch((e) => ({}));
      setTimeout(() => {
        let contacto = document.getElementById("contacto");
        window.scrollTo({
          top: contacto.offsetTop - 100,
          behavior: "smooth",
        });
      }, 100);
    },
    setSlide: function (val) {
      this.$store.commit("setSlide", val);
    },
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.desktop = true;
      } else {
        this.desktop = false;
      }
    },
    url: function (e) {
      window.open(e, "blank");
    },
    scrollChangeNavbar: function () {
      if (document.documentElement.scrollTop >= 100) {
        this.scrolled = false;
      }
      if (document.documentElement.scrollTop == 0) {
        this.scrolled = true;
        this.selcontact = false;
      }
    },
  },
  watch: {
    $route: function () {
      this.selected = this.$route.path.split("/")[1];
    },
  },
};
</script>

<style scoped>
.social-media {
  position: absolute;
  top: 10px;
  right: 140px;
  display: flex;
  align-items: center;
}
/* .space{
  width: 5%;
} */
.social-media img {
  width: 23px;
  height: 23px;
  margin-left: 15px;
}

.social-media img:hover {
  cursor: pointer;
  filter: brightness(0.5);
}

.content-texts {
  margin-top: -55px;
}

.txt-navbar:hover,
.txt-navbar-scrolled:hover {
  cursor: pointer;
  /* text-decoration: underline; */
}

.txt-navbar {
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  font-size: 25px;
  text-transform: uppercase;
}
.txt-navbar-logo {
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  font-size: 40px;
  text-transform: none;
  margin: 0% 10%;
}
.txt-navbar::first-letter {
  font-size: 30px;
}

.txt-navbar:nth-child(1),
.txt-navbar:nth-child(2) {
  margin-right: 5%;
}

.txt-navbar:nth-child(4),
.txt-navbar:nth-child(6) {
  margin-left: 5%;
}

.content-image {
  width: 500px;
  height: 270px;
  /* margin-top: 31px;
  margin-left: -50px; */
  margin: 31px 0px 55px -50px;
}

.content-image img {
  width: 100%;
  height: auto;
}

.contact-select {
  font-weight: light;
}

.contact-select-marker {
  font-weight: bold;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

@media (min-width: 961px) and (max-width: 1260px) {
  .social-media {
    right: 20px;
  }
}

/* -------------- styles scrolled navbar ------------- */
.social-media-scrolled {
  display: flex;
  align-items: center;
}

.social-media-scrolled img {
  width: 18px;
  height: 18px;
  margin-left: 15px;
}

.social-media-scrolled img:hover {
  cursor: pointer;
  filter: brightness(0.5);
}

.fixed-top {
  position: fixed;
  width: 100%;
  height: 100px;
  top: 0;
  z-index: 2;
}

.txt-navbar-scrolled {
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  font-size: 18px;
  text-transform: uppercase;
}

.txt-navbar-scrolled::first-letter {
  font-size: 23px;
}

.content-navbar-scrolled {
  background-color: #fff;
  padding: 20px;
  display: flex;
  align-items: center;
}

.content-image-scrolled {
  width: fit-content;
  height: 70px;
}

.content-image-scrolled img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image-scrolled img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

/* styles responsive navbar  */
.content-navbar-responsive {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 20px;
}

.fixed-top-responsive {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 6;
}

.content-image-responsive {
  width: 56px;
  height: 56px;
  margin-left: 60px;
}

.content-image-responsive img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image-responsive img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
  .txt-navbar-logo {
    min-width: 116px;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
    font-size: 40px;
    text-transform: none;
    margin: 0% 3%;
  }
  .txt-navbar:nth-child(1),
  .txt-navbar:nth-child(2) {
    margin-right: 3%;
  }

  .txt-navbar:nth-child(4),
  .txt-navbar:nth-child(6) {
    margin-left: 3%;
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
  .txt-navbar-logo {
    min-width: 116px;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
    font-size: 40px;
    text-transform: none;
    margin: 0% 5%;
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
  .txt-navbar-logo {
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
    font-size: 40px;
    text-transform: none;
    margin: 0% 15%;
  }
}
</style>
