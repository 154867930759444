<template>
  <div>
    <div
      class="background-banner display-flex align-items-center justify-content-center"
      :style="{ backgroundImage: backgroundImage }"
    >
      <p>Libros</p>
    </div>
    <v-container
      fluid
      class="global-paddings content-images-libros"
    >
      <v-row class="text-center">
        <v-col
          v-for="(item, index) in items"
          :key="index"
          cols="12"
          sm="12"
          md="6"
        >
          <div class="content-libro">
            <div class="display-flex align-items-center justify-content-center">
              <div class="content-image">
                <img :src="item.image">
              </div>
            </div>
            <div>
              <v-btn v-if="item.id === 0"
                @click="$router.push({ name: 'LibrosDetalle', params: { name: item.link } })"
                class="button-libros mb-9"
              >
                Ver prólogo
              </v-btn>
              <v-btn v-else
                @click="$router.push({ name: 'LibrosDetalle', params: { name: item.link } })"
                class="button-libros mb-9"
              >
                Ver argumento
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "LibrosView",
  data: () => ({
    backgroundImage: `url(${require("@/assets/images/banner.png")})`,
    bMobile: false,
    items: [
      {
        id: 0,
        image: require("@/assets/images/libro1.png"),
        link: "el-punto-animado",
      },
      {
        id: 1,
        image: require("@/assets/images/libro2.png"),
        link: "politicamente-incorrecta-primer-semestre",
      },
      {
        id: 2,
        image: require("@/assets/images/libro3.png"),
        link: "politicamente-incorrecta-segundo-semestre",
      },
    ],
  }),
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize(){
      if(window.innerWidth < 599){
        bMobile = true
      }else {
        bMobile = false
      }
    }
  },
};
</script>

<style scoped>
.button-libros {
  background-color: #a3332c !important;
  height: 40px !important;
  color: #fff;
  text-transform: initial;
  width: 200px;
  font-size: 18px;
}

.content-images-libros {
  margin-top: 50px;
  margin-bottom: 50px;
}

.content-libro {
  border: 1px solid #e2e2e2;
  border-radius: 23px;
  background-color: #fff;
}

.background-banner {
  height: 300px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 96px;
}

.background-banner p {
  text-align: center;
  font-size: 70px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
}

.content-image {
  width: 350px;
  height: 350px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

@media (max-width: 600px) {
  .button-libros {
    width: 80%;
  }
}
</style>