<template>
  <div class="content-inicio">
    <!-- #region Carousel and photos -->
    <div class="global-paddings">
      <img class="content-img-temp" src="@/assets/images/carousel/image1.jpeg" />

      <!-- <v-carousel v-model="carousel" :continuous="true" :cycle="true" :interval="4000" :show-arrows="false"
        hide-delimiters class="position-relative" :height="`${heightCarousel}px`">
        <v-carousel-item v-for="(item, index) in itemsCarousel" :key="index">
          <div class="carousel-images">
            <img :src="item.image">
          </div>
        </v-carousel-item>
        <div class="px-content-controls">
          <div v-for="(item, index) in itemsCarousel" :key="index"
            :class="(carousel == index) ? 'px-point-active px-point-carousel' : 'px-point-inactive px-point-carousel'"
            @click="carousel = index" />
        </div>
      </v-carousel> -->
      <v-container class="mt-5" fluid>
        <v-row class="ml-n6 mr-n6">
          <v-col
            v-for="(item, index) in itemsCategorias"
            :key="index"
            cols="12"
            sm="4"
            md="4"
          >
            <div>
              <div class="categorias-images">
                <img :src="item.image" />
              </div>
              <p class="txt-title-categoria">{{ item.title }}</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- #endregion Carousel and photos -->

    <div class="line" />

    <!-- #region ultimos lanzaminentos -->
    <div class="global-paddings mb-3">
      <p class="txt-lanzamientos">Últimos lanzamientos</p>
      <v-container class="mt-5" fluid>
        <v-row class="ml-n6 mr-n6">
          <v-col
            v-for="(item, index) in itemsLanzamientos"
            :key="index"
            cols="12"
            sm="4"
            md="4"
          >
            <div v-if="!bMobile" class="content-lanzamientos">
              <img class="img-lanzamiento" :src="item.image" />
              <p class="txt-title-lanzamientos">{{ item.nameBook }}</p>
              <p class="txt-date-lanzamientos">{{ item.date }}</p>
              <div class="overlay"></div>
              <div class="display-flex align-items-center justify-content-center">
                <v-btn v-if="item.id === 0"
                  class="button-lanzaminentos"
                  @click="
                    $router.push({ name: 'LibrosDetalle', params: { name: item.link } })
                  "
                  >Ver prólogo
                </v-btn>
                <v-btn v-else
                  class="button-lanzaminentos"
                  @click="
                    $router.push({ name: 'LibrosDetalle', params: { name: item.link } })
                  "
                  >Ver argumento
                </v-btn>
              </div>
            </div>
            <div v-else class="content-lanzamientos">
              <a @click="
                    $router.push({ name: 'LibrosDetalle', params: { name: item.link } })
                  ">
                <img :src="item.image" />
                <p class="txt-title-lanzamientos-w-hover">{{ item.nameBook }}</p>
                <p class="txt-date-lanzamientos-w-hover">{{ item.date }}</p>
                <!-- <div class="overlay"></div> -->
                <div class="display-flex align-items-center justify-content-center">
                  <!-- <v-btn
                  class="button-lanzaminentos"
                  @click="
                    $router.push({ name: 'LibrosDetalle', params: { name: item.link } })
                  "
                  >Ver prólogo</v-btn
                > -->
                </div></a
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- #endregion ultimos lanzaminentos -->

    <div class="line" />
    <!-- #region reseñas -->
    <div class="global-paddings mb-9">
      <p class="txt-noticias">Reseñas</p>
      <reseñas-component />
    </div>
    <!-- #endregion reseñas -->

    <div class="line" />

    <!-- #region acerca de mi -->
    <div class="global-paddings mt-9">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="5" class="pl-0">
            <div class="about-images">
              <img src="@/assets/images/mai500.png" />
            </div>
          </v-col>
          <v-col cols="12" md="5" offset-md="1">
            <div>
              <div class="display-flex align-items txts-about-acerca">
                <p>ACERCA</p>
                <p>DE</p>
                <p>MI…</p>
              </div>
              <p class="txt-about-nombre snell">María Fernanda</p>
              <p class="txt-about-description">
                María Fernanda García Sada (Monterrey, N.L., 1973) es licenciada en
                Estudios Internacionales, escritora y artista visual. Egresó de la
                Universidad de Monterrey en 1997. Hizo estudios de Gemología en Brasil con
                el maestro Rui Ribeiro, así como Protocolo y Etiqueta en el Institut Villa
                Pierrefeu en Suiza; Orfebrería, Diseño y Arte en el Instituto per l’Arte e
                il Restauro en Italia y Derecho de Arte y Administración de Galerías en
                Sotheby’s Institute of Art en los Estados Unidos.
              </p>
              <div @click="showMore" class="txt-link display-flex align-items-center">
                <p>Leer más</p>
                <v-icon size="16px" color="#006cff">mdi-chevron-right</v-icon>
              </div>
              <div id="show-more" class="show-more">
                <p class="txt-about-description">
                  Expuso en la Galería Emma Molina (2000 – 2006) y en GE Galería (2008 –
                  2012); Maestra de taller artístico privado y fundadora de la Galería Art
                  Suite en la Ciudad de México. Cuenta con más de veinte exposiciones
                  tanto de manera individual como colectiva en galerías y museos en México
                  y en el extranjero. En la escritura fue colaboradora de la revista
                  <span style="font-style: italic">The Business Club Magazine</span> con
                  la columna
                  <span style="font-style: italic">Arte, cultura y sociedad</span>, así
                  como también en la edición
                  <span style="font-style: italic">Woman</span> con la columna
                  <span style="font-style: italic">Women to Watch</span> de 2008 a 2012.
                  Realizó diplomados en Creación Literaria en el Museo MARCO y en la
                  Fábrica Literaria. En el 2013 escribió
                  <span style="font-style: italic">El punto animado</span> (edición de
                  autor). Ha estudiado Taller de Novela con Mariana García Luna, Haiku con
                  Cristina Rascón y Poesía con Amaranta Caballero Prado. También estudió
                  en Gotham Writers en Nueva York, en los Estados Unidos (2017-2019).
                </p>
              </div>
              <div
                @click="showMoreClose"
                class="txt-link-close display-flex align-items-center"
              >
                <p>Leer menos</p>
                <v-icon size="16px" color="#006cff">mdi-chevron-right</v-icon>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- #endregion acerca de mi -->

    <div class="line mt-4" />

    <!-- #region prensa -->
    <div class="mb-9">
      <p class="txt-noticias">Prensa</p>
      <noticias-component @getShowBtnPrensa="getShowBtnPrensa" />
      <div
        v-if="bShowBtnPrensa"
        @click="$router.push({ name: 'Prensa' })"
        class="display-flex align-items-center justify-content-center mt-5"
      >
        <v-btn class="button-registros"> Ver todos los registros </v-btn>
      </div>
    </div>
    <!-- #endregion prensa -->

    <!-- #region contacto -->
    <contact-component id="contacto" />
    <!-- #endregion contacto -->
  </div>
</template>

<script>
import ReseñasComponent from "@/components/Reseñas.vue";
import NoticiasComponent from "@/components/Noticias.vue";
import ContactComponent from "@/components/Contact.vue";

export default {
  name: "InicioView",
  data: () => ({
    carousel: 0,
    heightCarousel: 0,
    bMobile: false,
    itemsCarousel: [
      {
        image: require("@/assets/images/carousel/image1.png"),
      },
      {
        image: require("@/assets/images/carousel/image1.png"),
      },
      {
        image: require("@/assets/images/carousel/image1.png"),
      },
    ],
    itemsCategorias: [
      {
        title: "Literatura infantil",
        image: require("@/assets/images/IMG_3558.jpg"),
      },
      {
        title: "Novela juvenil",
        image: require("@/assets/images/novela.png"),
      },
      {
        title: "Poesía",
        image: require("@/assets/images/poesia.png"),
      },
    ],
    itemsLanzamientos: [
      {
        link: "el-punto-animado",
        date: "(2013)",
        image: require("@/assets/images/lanzamientos/libro3.png"),
        nameBook: "Literatura infantil",
        id: 0
      },
      {
        link: "politicamente-incorrecta-primer-semestre",
        date: "(2022)",
        image: require("@/assets/images/lanzamientos/libro2.png"),
        nameBook: "Novela juvenil",
        id: 1
      },
      {
        link: "politicamente-incorrecta-segundo-semestre",
        date: "(2022)",
        image: require("@/assets/images/lanzamientos/libro1.png"),
        nameBook: "Literatura juvenil",
        id: 2
      },
    ],
    bShowBtnPrensa: false,
  }),
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.heightCarousel = window.innerHeight - 350;
      } else {
        this.heightCarousel = window.innerHeight - 150;
      }
      if (window.innerWidth < 600) {
        this.bMobile = true;
      } else {
        this.bMobile = false;
      }
    },
    showMore: function () {
      let section = document.getElementById("show-more");
      let button = document.getElementsByClassName("txt-link")[0];
      button.style.display = "none";
      section.classList.add("fade");

      let buttonClose = document.getElementsByClassName("txt-link-close")[0];
      buttonClose.style.display = "flex";
    },
    showMoreClose: function () {
      let sectionClose = document.getElementById("show-more");
      let buttonClose = document.getElementsByClassName("txt-link-close")[0];
      buttonClose.style.display = "none";
      sectionClose.classList.remove("fade");

      let button = document.getElementsByClassName("txt-link")[0];
      button.style.display = "flex";

      // let section = document.getElementById("show-more");
      // let button = document.getElementsByClassName("txt-link")[0];
      // button.style.display = "blocke";
      // section.classList.add("fade");
    },
    getShowBtnPrensa(val) {
      this.bShowBtnPrensa = val;
    },
  },
  components: {
    ReseñasComponent,
    NoticiasComponent,
    ContactComponent,
  },
};
</script>

<style scoped>
.content-img-temp {
  height: 100%;
  width: 100%;
}

.content-inicio {
  padding-top: 20px;
}

.px-content-controls {
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.px-point-carousel {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin: 0px 7px;
}

.px-point-carousel:hover {
  cursor: pointer;
}

.px-point-active {
  background-color: #a3332c;
}

.px-point-inactive {
  background-color: #ffffff;
}

.button-registros {
  background: #a3332c 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0.3px;
  color: #ffffff;
  font-size: 18px;
  text-transform: initial;
}

.show-more {
  opacity: 0;
  display: none;
}

.fade {
  opacity: 1;
  transition: opacity 1s;
  display: block;
}

.txt-link {
  width: fit-content;
  height: 12px;
}
.txt-link-close {
  display: none;
  width: fit-content;
  height: 12px;
}

.txt-link p {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.19px;
  color: #006cff;
  opacity: 1;
  margin-bottom: 0;
}
.txt-link-close p {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.19px;
  color: #006cff;
  opacity: 1;
  margin-bottom: 0;
}

.txt-link:hover {
  cursor: pointer;
  border-bottom: 1px solid #006cff;
  color: #006cff;
}
.txt-link-close:hover {
  cursor: pointer;
  border-bottom: 1px solid #006cff;
  color: #006cff;
}

.txts-about-acerca {
  text-align: left;
  font-size: 16px;
  letter-spacing: 8px;
  color: #000000;
  opacity: 1;
}

.txts-about-acerca p::first-letter {
  font-size: 21px;
}

.txts-about-acerca p:nth-child(2) {
  margin-left: 5px;
  margin-right: 5px;
}

.txt-about-nombre {
  text-align: left;
  font-size: 55px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-left: 25px;
}

.txt-about-description {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #212121;
  opacity: 1;
  text-align: justify;
  text-justify: inter-word;
  line-height: 2;
}

.about-images {
  width: 100%;
  height: 500px;
}

.about-images img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .about-images img {
    height: 100%;
    object-fit: contain;
    object-position: top left;
  }
}

.txt-noticias {
  text-align: center;
  font-size: 45px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 30px;
}

.txt-lanzamientos {
  text-align: center;
  font-size: 45px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 30px;
  line-height: 1;
}

.line {
  border-top: 1px solid #e2e2e2;
}

.carousel-images {
  width: 100%;
  height: 100%;
}

.carousel-images img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .carousel-images img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.txt-title-categoria {
  text-align: center;
  font-size: 30px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 10px;
}

.categorias-images {
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.categorias-images:hover img {
  transform: scale(1.2);
}

.categorias-images img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .categorias-images img {
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }
}

/* effect lanzamientos  */
.content-lanzamientos {
  position: relative;
  width: 100%;
  height: 310px;
}

@supports (object-fit: contain) {
  .content-lanzamientos img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  transition: background 0.5s ease;
}

.content-lanzamientos:hover .overlay {
  display: block;
  background: rgba(255, 255, 255, 0.3);
}

.content-lanzamientos:hover .img-lanzamiento {
  margin-top: -58px;
}

.content-lanzamientos img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}

.txt-title-lanzamientos {
  position: absolute;
  width: 100%;
  left: 0;
  top: 260px;
  text-transform: initial;
  z-index: 1;
  transition: top 0.5s ease;
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.txt-title-lanzamientos-w-hover {
  position: absolute;
  width: 100%;
  left: 0;
  top: 310px;
  text-transform: initial;
  z-index: 1;
  transition: top 0.5s ease;
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.txt-date-lanzamientos {
  position: absolute;
  width: 100%;
  left: 0;
  top: 280px;
  text-transform: uppercase;
  z-index: 1;
  transition: top 0.5s ease;
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.txt-date-lanzamientos-w-hover {
  position: absolute;
  width: 100%;
  left: 0;
  top: 330px;
  text-transform: uppercase;
  z-index: 1;
  transition: top 0.5s ease;
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.content-lanzamientos:hover .txt-title-lanzamientos {
  top: 230px;
}

.content-lanzamientos:hover .txt-date-lanzamientos {
  top: 260px;
}

.button-lanzaminentos {
  position: absolute;
  width: 210px;
  /* left: 0; */
  top: 190px;
  transition: opacity 0.35s ease;
  background: #a3332c 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  text-align: center;
  font-size: 20px;
  letter-spacing: 0.3px;
  color: #ffffff;
  opacity: 0;
  z-index: 1;
  text-transform: initial;
}

.content-lanzamientos:hover .button-lanzaminentos {
  opacity: 1;
}

@media (max-width: 600px) {
  .content-lanzamientos {
    height: 350px;
  }

  .txt-title-lanzamientos {
    top: 310px;
  }

  .txt-date-lanzamientos {
    top: 330px;
  }
}

@media (max-width: 960px) {
  .about-images {
    width: 100%;
    height: auto;
  }

  @supports (object-fit: cover) {
    .about-images img {
      object-fit: cover;
      object-position: center center;
    }
  }

  .content-inicio {
    padding-top: 96px;
  }
}

@media (min-width: 1525px) {
  .categorias-images {
    height: 550px;
  }
}
</style>
