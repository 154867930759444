<template>
  <div v-if="isDesktop" class="content-footer global-paddings">
    <div
      class="display-flex align-items-center justify-content-center position-relative"
    >
      <v-spacer />
      <p class="txt-footer">
        Todos los derechos reservados María Fernanda García Sada™ ©
      </p>
      <div class="content-image">
        <img src="@/assets/images/logo-sello.png" />
      </div>
      <p class="txt-footer txt-link" @click="getPDFAvisoPrivacidad()">Aviso de privacidad</p>
      <p class="txt-footer txt-link" @click="getPDFTerminosCondiciones()">
        Términos y condiciones
      </p>
      <v-spacer />
      <div class="social-media">
        <img
          @click="url('https://www.instagram.com/mai_gs_ya/')"
          src="@/assets/images/instagram.png"
        />
        <img
          @click="url('https://www.facebook.com/mai.gs1')"
          src="@/assets/images/facebook.png"
        />
        <img
          @click="url('https://twitter.com/Mai_Gs1')"
          src="@/assets/images/twitter.png"
        />
        <v-btn class="button-access" icon>
          <v-icon color="#e2e2e2" size="14px"> mdi-copyright </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
  <div v-else class="content-footer-responsive">
    <div class="display-flex align-items-center justify-content-center">
      <div class="content-image">
        <img src="@/assets/images/logo-sello.png" />
      </div>
    </div>
    <p class="txt-footer text-center mt-5">
      Todos los derechos reservados María Fernanda García Sada™ ©
    </p>
    <p class="txt-footer txt-link text-center mt-5" @click="getPDFAvisoPrivacidad()">
      Aviso de privacidad
    </p>
    <p class="txt-footer txt-link text-center mt-5 mb-5" @click="getPDFTerminosCondiciones()">
      Términos y condiciones
    </p>
    <div class="display-flex align-items-center justify-content-center">
      <div style="border-top: 1px solid #e2e2e2; width: 90%" />
    </div>
    <div
      class="display-flex align-items-center justify-content-center pt-5 pb-5"
    >
      <div class="social-media">
        <img
          @click="url('https://www.instagram.com/mai_gs_ya/')"
          src="@/assets/images/instagram.png"
        />
        <img
          @click="url('https://www.facebook.com/mai.gs1')"
          src="@/assets/images/facebook.png"
        />
        <img
          @click="url('https://twitter.com/Mai_Gs1')"
          src="@/assets/images/twitter.png"
        />
        <v-btn class="button-access" icon>
          <v-icon color="#e2e2e2" size="14px"> mdi-copyright </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data: () => ({
    isDesktop: true,
  }),
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    url: function (url) {
      window.open(url, "blank");
    },
    getPDFAvisoPrivacidad() {
      let popUp = window.open(
        "https://public-mai-images.s3.amazonaws.com/Aviso+de+privacidad+-+MaiGs.pdf",
        "_blank"
      );
      if (popUp === null || typeof popUp === "undefined") {
        this.Error("Activar ventana emergente del navegador");
      } else {
        popUp.focus();
      }
    },
    getPDFTerminosCondiciones() {
      let popUp = window.open(
        "https://public-mai-images.s3.amazonaws.com/Te%CC%81rminos+y+condiciones+-+MaiGS.pdf",
        "_blank"
      );
      if (popUp === null || typeof popUp === "undefined") {
        this.Error("Activar ventana emergente del navegador");
      } else {
        popUp.focus();
      }
    },
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.isDesktop = true;
      } else {
        this.isDesktop = false;
      }
    },
  },
};
</script>

<style scoped>
.txt-link {
  text-decoration: underline;
  color: cornflowerblue !important;
  cursor: pointer;
}
.button-access {
  width: fit-content !important;
  height: fit-content;
  position: absolute;
  right: 0px;
  top: 20px;
}

.social-media {
  display: flex;
  align-items: center;
  position: relative;
}

.social-media img {
  width: 16px;
  height: 16px;
  filter: brightness(0.5);
}

.social-media img:nth-child(2) {
  width: 16px;
  height: 16px;
  margin-left: 15px;
  margin-right: 15px;
}

.social-media img:hover {
  cursor: pointer;
  filter: brightness(1);
}

.content-footer {
  border-top: 1px solid #e2e2e2;
  padding: 20px;
}

.txt-footer {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
}

.txt-footer:nth-child(4) {
  margin-right: 25px;
}

.content-image {
  width: 60px;
  height: 60px;
  margin-left: 25px;
  margin-right: 25px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

/* style responsive design  */
.content-footer-responsive {
  background-color: #fff;
  border-top: 1px solid #e2e2e2;
  padding: 20px;
  z-index: 4;
  position: relative;
}

@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .txt-footer {
    margin-right: 0px !important;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .txt-footer {
    margin-right: 0px !important;
  }
}
</style>
