<template>
  <div
    :style="{
      width: `${fullWidth}`,
      height: `${fullHeight}`,
      overflow: 'hidden',
      position: 'fixed',
    }"
  >
    <div class="content-effect">
      <div class="shadow">
        <div
          id="triangle"
          class="triangle"
          :style="[
            {
              width: `${fullWidth}`,
              height: `${middleHeight}`,
            },
            cssProps,
          ]"
        />
      </div>
      <div
        id="square"
        class="square"
        :style="{
          width: `${fullWidth}`,
          height: `${fullHeight}`,
        }"
      />
    </div>
    <v-btn class="button-content-gif" :ripple="false">
      <div class="content-click-effect"></div>
      <!-- @click="showEffect" -->
      <div @click="showEffect" v-show="showGif" class="click-effect">
        <img
          v-show="imgLogoShow"
          class="img-logo-full"
          :src="require('@/assets/images/pedazos/MaiGsselloFull.png')"
        />
        <img
          id="imgLogo"
          class="img-logo"
          :src="require('@/assets/images/pedazos/MaiGssello.png')"
        />
        <img
          id="imgLogo1"
          class="img-logo1"
          :src="require('@/assets/images/pedazos/MaiGssello1.png')"
        />
        <img
          id="imgLogo2"
          class="img-logo2"
          :src="require('@/assets/images/pedazos/MaiGssello2.png')"
        />
        <img
          id="imgLogo3"
          class="img-logo3"
          :src="require('@/assets/images/pedazos/MaiGssello3.png')"
        />
        <img
          id="imgLogo4"
          class="img-logo4"
          :src="require('@/assets/images/pedazos/MaiGssello4.png')"
        />
        <img
          id="imgLogo5"
          class="img-logo5"
          :src="require('@/assets/images/pedazos/MaiGssello5.png')"
        />
      </div>
    </v-btn>
    <div style="z-index: 1; position: relative">
      <navbar-component />
      <inicio-view />
    </div>
  </div>
</template>

<script>
import NavbarComponent from "@/components/Navbar.vue";
import InicioView from "@/views/Inicio.vue";
import { VueFreezeframe } from "vue-freezeframe";

export default {
  name: "EffectComponent",
  data: () => ({
    fullWidth: 0,
    fullHeight: 0,
    middleHeight: 0,
    showGif: true,
    imgLogoShow: true,
  }),
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    showEffect: function () {
      this.imgLogoShow = false;
      let triangle = document.getElementById("triangle");
      let square = document.getElementById("square");
      let imgLogo = document.getElementById("imgLogo");
      let imgLogo1 = document.getElementById("imgLogo1");
      let imgLogo2 = document.getElementById("imgLogo2");
      let imgLogo3 = document.getElementById("imgLogo3");
      let imgLogo4 = document.getElementById("imgLogo4");
      let imgLogo5 = document.getElementById("imgLogo5");

      if (triangle) {
        triangle.classList.add("add-slider-triangle");
      }
      if (square) {
        square.classList.add("add-slider-square");
      }
      if (imgLogo) {
        imgLogo.classList.add("add-img-logo");
      }
      if (imgLogo1) {
        imgLogo1.classList.add("add-img-logo1");
      }
      if (imgLogo2) {
        imgLogo2.classList.add("add-img-logo2");
      }
      if (imgLogo3) {
        imgLogo3.classList.add("add-img-logo3");
      }
      if (imgLogo4) {
        imgLogo4.classList.add("add-img-logo4");
      }
      if (imgLogo5) {
        imgLogo5.classList.add("add-img-logo5");
      }
      setTimeout(() => {
        this.showGif = false;
      }, 1900);
      setTimeout(() => {
        this.$router.push({
          name: "Inicio",
        });
      }, 2000);
    },
    handleResize: function () {
      this.fullWidth = window.innerWidth + "px";
      this.middleHeight = window.innerHeight / 2 + "px";
      this.fullHeight = window.innerHeight + "px";
    },
  },
  computed: {
    cssProps() {
      return {
        "--height": this.middleHeight,
      };
    },
  },
  components: {
    InicioView,
    NavbarComponent,
    VueFreezeframe,
  },
};
</script>

<style>
.ff-container .ff-overlay {
  display: none !important;
}

.ff-container.ff-responsive {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ff-container .ff-canvas {
  top: initial !important;
}
</style>

<style scoped>
.content-click-effect {
  position: absolute;
  z-index: 4;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  align-items: center;
  text-align: center !important;
  justify-content: center;
  display: flex;
  cursor: auto;
}
.click-effect {
  position: absolute;
  z-index: 6;
  width: 200px;
  height: 17%;
  border-radius: 100%;
  align-items: center;
  text-align: center !important;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
.img-logo-full {
  position: absolute;
  width: 100%;
  z-index: 9;
}
.img-logo {
  position: absolute;
  width: 100%;
}
.img-logo1 {
  position: absolute;
  width: 100%;
}
.img-logo2 {
  position: absolute;
  width: 100%;
}
.img-logo3 {
  position: absolute;
  width: 100%;
}
.img-logo4 {
  position: absolute;
  width: 100%;
}
.img-logo5 {
  position: absolute;
  width: 100%;
}

.button-content-gif {
  position: absolute;
  z-index: 4;
  left: 0;
  top: 0;
  width: 100%;
  height: 100% !important;
  background-color: transparent !important;
}
.button-content-gif::before {
  background: transparent !important;
}
.content-gif {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.content-gif img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-gif img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

.content-effect {
  position: absolute;
  top: 0;
  left: 0;
}

.square {
  background-color: #f9f9f4;
  position: absolute;
  top: 0px;
  z-index: 2;
  box-shadow: 20px 4px 15px 5px #00000050;
}

.triangle {
  background-color: #f9f9f4;
  clip-path: polygon(100% 0, 100% 40%, 50% 100%, 0 40%, 0 0);
}

.shadow {
  filter: drop-shadow(-6px 6px 7px rgba(0, 0, 0, 0.2));
  position: relative;
  z-index: 3;
}

.btn-icon .theme--light.v-btn.v-btn--has-bg {
  background: #f9f9f4 !important;
}

/*#region slider triangle */
.add-slider-triangle {
  animation: triangleEffect 2s;
}
@keyframes triangleEffect {
  0% {
    /* height: 40vh; */
    /* height: v-bind("middleHeight"); */
    /* height: var(--height); */
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1000px);
  }
}
/*#endregion slider triangle */

/*#region silder square */
.add-slider-square {
  animation: squareEffect 2s;
}
@keyframes squareEffect {
  100% {
    /* top: v-bind("fullHeight"); */
    /* top: 1000px; */
    transform: translateY(1000px);
  }
  0% {
    /* top: 0px; */
    transform: translateY(0);
  }
}
/*#endregion silder square */

/*#region Effect logo imgs */
.add-img-logo {
  animation: imgLogoEffect 4s;
}
@keyframes imgLogoEffect {
  0% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(calc(-100vw - 80px), calc(-100vh - 80px));
  }
}
.add-img-logo1 {
  animation: imgLogoEffect1 4s;
}
@keyframes imgLogoEffect1 {
  0% {
    transform: translateX(0);
    transform: rotate(0turn);
  }
  100% {
    transform: translateX(-1000px);
  }
}
.add-img-logo2 {
  animation: imgLogoEffect2 4s;
}
@keyframes imgLogoEffect2 {
  0% {
    transform: translate(0px 0px);
  }

  90% {
    width: 2000px;
  }
  100% {
    /* width: 2000px; */
    transform: translate(-1000px, 1000px) rotate(90deg);
  }
}
.add-img-logo3 {
  animation: imgLogoEffect3 4s;
}
@keyframes imgLogoEffect3 {
  0% {
    transform: translateX(0);
  }
  90% {
    width: 700px;
  }
  100% {
    width: 700px;
    transform: translateX(1000px) rotate(90deg);
  }
}
.add-img-logo4 {
  animation: imgLogoEffect4 4s;
}
@keyframes imgLogoEffect4 {
  0% {
    transform: translate(0px 0px);
  }

  90% {
  }
  100% {
    transform: translate(1000px, -1000px) rotate(90deg);
  }
}
.add-img-logo5 {
  animation: imgLogoEffect5 4s;
}
@keyframes imgLogoEffect5 {
  0% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(calc(100vw - 50px), calc(100vh - 50px));
  }
}
/*#endregion Effect logo imgs */

/*#region Responsive css */

/*#region XS */
@media (max-width: 599px) {
}
/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
}
/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
}
/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
}
/*#endregion LG */
/*#region XL */
@media (min-width: 1904px) {
}
/*#endregion XL */
/*#endregion Responsive css */
</style>