<template>
  <v-container v-if="isDesktop" fluid class="global-paddings">
    <v-row :class="!card ? 'card-center' : 'card-none-center'">
      <v-col v-for="(item, index) in items" :key="index" sm=6 md="3" v-if="index <= 3">
        <div @click="goDetail(item.sArticleId)" class="px-content-card">
          <div class="content-image">
            <img v-if="item.sImageUrl" :src="item.sImageUrl">
            <img v-else src="@/assets/images/img-placeholder.png">

          </div>
          <div class="px-body-card">
            <p class="txt-title">{{ item.sTitle }}</p>
            <p class="txt-description">{{ item.sContent }}</p>
            <p class="txt-date">
                  {{ item.tPublishDate }}
                </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <div v-if="items.length === 0" class="content-empty-card">
      <span class="txt-empty-card">"Sin información"</span>
    </div>
  </v-container>
  <div v-else class="scroller-x">
    <div v-for="(item, index) in items" :key="index" v-if="index <= 3" @click="goDetail(item.sArticleId)"
      class="px-content-card-responsive">
      <div class="content-image">
        <img v-if="item.sImageUrl" :src="item.sImageUrl">
        <img v-else src="@/assets/images/img-placeholder.png">

      </div>
      <div class="px-body-card">
        <p class="txt-title">{{ item.sTitle }}</p>
        <p class="txt-description">{{ item.sContent }}</p>
        <p class="txt-date">
                  {{ item.tPublishDate }}
                </p>
      </div>
    </div>
    <div v-if="items.length === 0" class="content-empty-card">
      <span class="txt-empty-card">"Sin información"</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "NoticiasComponent",
  data: () => ({
    isDesktop: true,
    card: false,
    items: [
      // {
      //   id: "frfer-45445-test-rt-rtrtrt-666",
      //   image: "http://via.placeholder.com/300",
      //   title: "First-line item",
      //   description:
      //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Assumenda pariatur quisquam, iste mollitia exercitationem, modi odio reprehenderit aut velit, molestias molestiae qui fugiat quo veniam accusamus laudantium commodi? Aspernatur, sed.",
      // },
      // {
      //   id: "frfer-45445-test-rt-rtrtrt-666",
      //   image: "http://via.placeholder.com/300",
      //   title: "First-line item",
      //   description:
      //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Assumenda pariatur quisquam, iste mollitia exercitationem, modi odio reprehenderit aut velit, molestias molestiae qui fugiat quo veniam accusamus laudantium commodi? Aspernatur, sed.",
      // },
      // {
      //   id: "frfer-45445-test-rt-rtrtrt-666",
      //   image: "http://via.placeholder.com/300",
      //   title: "First-line item",
      //   description:
      //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Assumenda pariatur quisquam, iste mollitia exercitationem, modi odio reprehenderit aut velit, molestias molestiae qui fugiat quo veniam accusamus laudantium commodi? Aspernatur, sed.",
      // },
      // {
      //   id: "frfer-45445-test-rt-rtrtrt-666",
      //   image: "http://via.placeholder.com/300",
      //   title: "First-line item",
      //   description:
      //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Assumenda pariatur quisquam, iste mollitia exercitationem, modi odio reprehenderit aut velit, molestias molestiae qui fugiat quo veniam accusamus laudantium commodi? Aspernatur, sed.",
      // },
    ],
  }),
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeMount() {
    this.getPrensas();
  }
  ,
  methods: {
    cardSize: function(){
      if(this.items < 3){
        this.card = true;
      }else {
        this.card = false;
      }
    },
    handleResize: function () {
      if (window.innerWidth > 600) {
        this.isDesktop = true;
      } else {
        this.isDesktop = false;
      }
    },
    getPrensas() {
      let params = {
        // sSearch: this.sSearch,
      };
      DB.get(`${URI}/articles`, {
        params,
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.items = [];
          if (this.$route.params.id !== undefined) {
            this.items = response.data.articles.filter(e => e.sArticleId !== this.$route.params.id)

          } else {
            this.items = response.data.articles
          }

          this.$emit("getShowBtnPrensa", this.items.length > 0)
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.Error(error.response.data.message);
        });
    },
    goDetail(sArticleId) {
      if (this.$route.name) {
        this.$router.push({ name: 'PrensaDetalle', params: { id: sArticleId } }).catch(e => { });
        this.$store.commit("refresher", true);
      } else {
        this.$router.push({ name: 'PrensaDetalle', params: { id: sArticleId } }).catch(e => { });
      }
    }
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refreshTable() {
      this.getPrensas();

    }
  },
};
</script>

<style scoped>
.content-empty-card {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.txt-empty-card {
  margin-bottom: 0px !important;
  font-style: italic;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "GaraPro-Regular" !important;
  color: #b9bfa9 !important;
}

/* responsive cards  */
.scroller-x {
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
}

.px-content-card-responsive {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
  min-width: 250px;
}

.px-content-card-responsive:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 0.5;
}

.px-content-card-responsive:nth-child(2) {
  margin-left: 30px;
  margin-right: 15px;
}

.px-content-card-responsive:nth-child(3) {
  margin-left: 15px;
  margin-right: 30px;
}

/* end: responsive cards  */

.px-body-card {
  padding: 20px;
}

.txt-title {
  text-align: left;
  font-size: 22px;
  letter-spacing: 0.18px;
  color: #000000de;
  opacity: 1;
  /* two lines  */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* margin-bottom: 0; */
}

.txt-description {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.13px;
  color: #00000099;
  opacity: 1;
  /* two lines  */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
}

.txt-date{
  text-align: center;
  margin-top: 3px;
  font-size: 14px;
  letter-spacing: 0.13px;
  color: #a3332c;
  opacity: 1;
}
.container-cards {
  padding: 50px 0px;
}

.px-content-card {
  height: 300px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.px-content-card:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 0.5;
}

.content-image {
  width: 100%;
  height: 150px;
}

.content-image img {
  width: 100%;
  height: auto;
}

.card-center {
  place-content: center;
}

.card-none-center {
  place-content: left;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 110%;
    object-fit: contain;
    object-position: center center;
    border-radius: 3px;
  }
}
</style>