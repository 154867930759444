<template>
  <div id="contact-template" class="content-contact">
    <p class="txt-title">Contacto</p>
    <div class="display-flex align-items-center justify-content-center">
      <div class="content-form">
        <v-text-field
          v-model="nombre"
          background-color="#F0F0EA"
          color="#000"
          class="input-contact"
          label="Nombre"
          persistent-placeholder
          type="text"
          placeholder="Escriba aqui su nombre"
        ></v-text-field>
        <v-text-field
          v-model="correo"
          background-color="#F0F0EA"
          color="#000"
          class="input-contact"
          label="Correo"
          persistent-placeholder
          maxLength="74"
          placeholder="Escriba aqui su correo"
        ></v-text-field>
        <v-select
          v-model="motivo"
          :clearable="motivo !== null ? true : false"
          :items="aMotivo"
          item-value="iValue"
          item-text="sName"
          background-color="#F0F0EA"
          color="#000"
          class="input-contact"
          label="Motivo"
        ></v-select>
        <v-select
          v-if="motivo === 1"
          v-model="libro"
          :clearable="libro !== null ? true : false"
          :items="aLibro"
          item-value="iValue"
          item-text="sName"
          background-color="#F0F0EA"
          color="#000"
          class="input-contact"
          label="Libro"
        ></v-select>
        <v-text-field
          v-if="motivo === 1"
          v-model="JobDescription"
          background-color="#F0F0EA"
          color="#000"
          class="input-contact"
          label="Puesto"
          persistent-placeholder
          maxLength="74"
          placeholder="Escriba aqui su puesto"
        ></v-text-field>
        <v-textarea
          v-model="mensaje"
          background-color="#F0F0EA"
          color="#000"
          class="input-contact"
          label="Mensaje"
          persistent-placeholder
          counter="600"
          maxLength="650"
          placeholder="Escriba su mensaje"
        ></v-textarea>
        <v-btn
          class="button-form mt-4"
          :loading="bLoading"
          :disabled="!bValidateForm"
          @click="setSave()"
          ><span class="text-send">Enviar</span></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactComponent",
  data: () => ({
    nombre: "",
    correo: "",
    motivo: null,
    JobDescription: "",
    aMotivo: [
      { sName: "Selecciona el motivo", iValue: null, disabled: true },
      { sName: "Solicitud de contacto", iValue: 0 },
      { sName: "Reseña", iValue: 1 },
    ],
    libro: null,
    aLibro: [
      { sName: "Selecciona un libro:", iValue: null, disabled: true },
      { sName: "El punto animado", iValue: 1 },
      { sName: "Políticamente incorrecta Primer semestre", iValue: 2 },
      { sName: "Políticamente incorrecta Segundo semestre", iValue: 3 },
    ],
    mensaje: "",
    bLoading: false,
  }),
  methods: {
    soloNumbers: function (evt) {
      if ((evt.which != 8 && evt.which != 0 && evt.which < 48) || evt.which > 57) {
        evt.preventDefault();
      }
    },
    soloLetters: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    setSave() {
      this.bLoading = true;
      const payload = {
          sName: this.nombre,
          sEmail: this.correo,
          iType: this.motivo,
          sJobDescription: this.JobDescription,
          iBook: this.libro == null ? 1 : this.libro,
          sMessage: this.mensaje,
        },
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        };
      DB.post(`${URI}/messages`, payload, config)
        .then((response) => {
          this.bLoading = false;
          this.clearForm();
          this.Success(response.data.messageSuccess);
        })
        .catch((error) => {
          this.bLoading = false;
          this.Error(error.response.data.message);
        });
    },
    clearForm() {
      this.nombre = "";
      this.correo = "";
      this.motivo = null;
      this.JobDescription = "";
      this.libro = null;
      this.mensaje = "";
    },
  },
  computed: {
    bValidateForm() {
      switch (this.motivo) {
        case 0: // validacion con motivo = Comentario
          return (
            this.nombre !== "" &&
            this.correo !== "" &&
            this.motivo !== null &&
            this.mensaje !== ""
          );
        case 1: // validacion con motivo = Reseña
          return (
            this.nombre !== "" &&
            this.correo !== "" &&
            this.motivo !== null &&
            this.libro !== null &&
            this.mensaje !== ""
          );
        default:
          return false;
      }
    },
  },
  watch: {
    motivo() {
      this.libro = null;
    },
  },
};
</script>

<style>
.input-contact label {
  color: #757575 !important;
  font-size: 22px;
  font-weight: bold;
  top: 0px !important;
}

.v-input input {
  max-height: 32px;
  margin-left: 10px;
}
.v-select__selection--comma {
  margin: 7px 4px 7px 10px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.v-textarea textarea {
  align-self: stretch;
  flex: 1 1 auto;
  line-height: 1.75rem;
  max-width: 100%;
  min-height: 32px;
  outline: none;
  padding: 0;
  width: 100%;
  margin-left: 10px;
}

/* .v-list-item__title:hover{
  color: #ffd54f !important;
} */

.v-list .v-list-item--active {
  background-color: #dddddd !important;
  color: #dddddd !important;
}

.v-list .v-list-item--active .v-list-item__title {
  color: black !important;
}
</style>

<style scoped>
.content-contact {
  background-color: #fafafa;
  /* background-color: gray; */
  padding: 50px 0px;
}

.txt-title {
  text-align: center;
  font-size: 50px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.content-form {
  width: 600px;
}

.button-form {
  background: #a3332c 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  font-size: 18px;
  letter-spacing: 0.3px;
  color: #ffffff;
  height: 40px;
  width: 100%;
  text-transform: initial;
  transition: none !important;
}
.text-span {
  transition: none !important;
}

@media (max-width: 600px) {
  .content-contact {
    width: 100%;
    padding: 20px;
  }
}
</style>
